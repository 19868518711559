// import img1 from './images/crop-1.jpg'
// import img2 from './images/crop-2.jpg'
// import img3 from './images/crop-3.jpg'
// import img4 from './images/crop-4.jpg'
// import img5 from './images/crop-5.jpg'
// import img6 from './images/crop-6.jpg'
import logoDefaultBlack from './images/logo-default-black.png'
import logoDefaultWhite from './images/logo-default-white.png'
// import logoInvertBlack from './images/logo-invert-black.png'
// import logoInvertWhite from './images/logo-invert-white.png'
// import img7 from './images/crop-7.jpg'
// import img8 from './images/crop-8.jpg'
// import img9 from './images/crop-9.jpg'
// import img10 from './images/crop-10.jpg'
// import img11 from './images/crop-11.jpg'
// import img12 from './images/crop-12.jpg'
// import img13 from './images/crop-13.jpg'
// import img14 from './images/crop-14.jpg'

export default [
//   [img6, -0.5585486420134362, -3.9373147490685803, 5, 0, 0.75],
    [logoDefaultBlack, 0, 0 + 0.0 * 1, -0.0 * 0.8 + 1, 0, 0.2, 1116, 428],
    [logoDefaultWhite, 0, 0 + 0.1 * 1, -0.1 * 0.8 + 1, 0, 0.2, 1116, 428],
    [logoDefaultBlack, 0, 0 + 0.2 * 1, -0.2 * 0.8 + 1, 0, 0.2, 1116, 428],
    [logoDefaultWhite, 0, 0 + 0.3 * 1, -0.3 * 0.8 + 1, 0, 0.2, 1116, 428],
    [logoDefaultBlack, 0, 0 + 0.4 * 1, -0.4 * 0.8 + 1, 0, 0.2, 1116, 428],
    [logoDefaultWhite, 0, 0 + 0.5 * 1, -0.5 * 0.8 + 1, 0, 0.2, 1116, 428],
    // [logoDefaultBlack, 0, -3, 3, 0, 0.75, 1116, 428],
    // [logoDefaultWhite, 0, -3, 4, 0, 0.81, 1116, 428],
//   [img2, 1.2658082998471168, -11.546249109522778, 10, 0.25, 0.725],
//   [img3, -1.9467783978748945, -14.812371431221711, 15, 0.25, 0.7],
//   [img4, 2.99601069694845874, -4.10862465704651, 20, 0.75, 0.675],
//   [img5, -1.8993567183341669, -14.813132752114951, 25, 1, 0.65],
//   [img6, 1.8375452075389486, -12.13103791856008, 30, 1, 0.625],
//   [img7, -1.8776788044001291, -12.032336156141714, 35, 1.5, 0.6],
//   [img8, 1.0749348594509635, -11.031316605440301, 40, 1.75, 0.575],
//   [img9, -2.4000439407834233, -13.164578222990698, 45, 2, 0.55],
//   [img10, -2.7065939344543151, -8.871286874370471, 40, 1.25, 0.525],
//   [img11, 0.3849091162266927, -20.0874592327078005, 30, 1.25, 0.5],
//   [img12, -2.566920223782157, -7.577466274058035, 16, 1.75, 0.475],
//   [img13, 1.472727466653086, -16.016837845215539, 20, 1.5, 0.45],
//   [img14, 1.5343322678763767, -16.235258169213427, 30, 2, 0.425]
]
